@import '../index.scss';

.landing-subheader {
    color: #fff;
    font-size: 2.5em;
}

.landing-subheader {
    position: relative;
    display: inline-block; 
    padding-bottom: 8px; 
}

.landing-subheader:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: -5px; 
    width: calc(100% + 10px); 
    height: 8px; 
    background-color: $accent-blue; 
}

@media screen and (max-width: 767px) {
    .landing-subheader {
        font-size: 2em;
    }
}

