@import '../../index.scss';

.footer-container {
    border-top: 1.5px solid $accent-blue;
    background: darken($main-background-dark, 3%);
}

.footer-header {
    position: relative;
    margin-bottom: 1.5em;
}

.footer-header::after {
    content: '';
    position: absolute;
    bottom: -10px; 
    left: 0; 
    width: 5em; 
    height: 1px;
    background-color: $accent-blue;
    transition: none; 
    z-index: 1;
}

.upper-footer-container {
    padding: 2em
}

.footer-logo {
    width: 25em !important;
}

@media screen and (max-width: 767px) {
    .footer-anchor {
        font-size: .8em;    
    }

    .footer-about-blurb {
        margin-bottom: 3em;
    }

    .footer-logo {
        width: 20em !important;
    }
    
}
  
  