@import '../../index.scss';

.page-header {
    margin-top: 3em;
    position: relative; 
    z-index: 2; 
    text-align: center; 
    color: #fff;
    font-size: 2.5em;
}

.upper-page-container {
    background-color: darken($main-background-dark, 2.5%);
    height: 65vh;
    position: relative;
    overflow: hidden;
}
 
.upper-page-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba($accent-blue, .7) 0, rgba($accent-blue, .4) 100%);
    opacity: 0.25;
}

.upper-page-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../../assets/northern-lights.webp) no-repeat center center;
    background-size: cover;
    opacity: 0.225;
}

@media screen and (max-width: 767px) {
    .page-header {
        margin-top: 4em;
    }
    
    .upper-page-container {
        height: 15em;
    }
}