@import '../index.scss';

.contact-page-container {
    background: $neutral-white;
}

.contact-page-main-row {
    padding: 3em 0;
}

.mail-img {
    width: 12em !important;
}

.contact-blurb-text {
    color: $main-background-dark-inverted;
}

@media screen and (min-width: 768px) {
    .mail-img {
        width: 12em;
    }

    .contact-blurb-text {
        color: $main-background-dark-inverted;
        font-size: 1.15em;
    }
}

