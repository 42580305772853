@import '../../index.scss';

$accent-blue-light: lighten($accent-blue, 15%);

.card.price-card {
    background: transparent;
    border: 3px solid $accent-blue; 
    background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue-light, 0.15) 100%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, border 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(130, 50, 50, 0.1);
}

.card.price-card:hover {
    transform: translateY(-5px); 
    background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue-light, 0.25) 100%);
    border: 3px solid $accent-teal;

    .item-subtitle,
    .price-card-summary {
        opacity: 1;
    }
}

.card .price-card-header {
    color: #fff;
}

.price-card-list-group .list-group-item {
    border: none; 
    position: relative; 
    background: transparent;
}
 
.price-card-summary {
    padding: 1.25em;
    margin: 0;
    color: #fff;
    opacity: 85%;
}

.price-card h6 {    
    margin-bottom: 0;
}

.price-text {
    font-size: 3em;
}

.card-text {
    font-size: 1.15em;
}

.price-card .item-subtitle {
    color: #fff;
    opacity: 85%
}

.price-container {
    position: relative;
    text-align: center;
}

.price-monthly-text {
    display: block;
    font-size: 1rem;
    color: $accent-blue;
}

.prices-subsection-inverted {
    .price-card-summary,
    .price-card .item-subtitle,
    .price-card-header  {
        color: #fff;
    }

    .card.price-card {
        background: $main-background-dark;
        border: 3px solid $accent-blue;
        background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue-light, 0.15) 100%);
    }
     
    .card.price-card:hover {
        border: 3px solid $accent-teal;
        background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue-light, 0.25) 100%);
    }
}

