@import '../index.scss';

.services-page-main-container {
    h1, h2, h3, h4, h5, h6, p {
        color: $main-background-dark-inverted;
    }
}

.services-summaries-container {
    img {
        height: 15em;
    }
}

.services-page-sub-section {
    padding-top: 4em;
}

.services-summary-img {
    width: 5em;
}

.services-summary-container {
    padding: 1em;
    border: 1.5px solid $main-background-dark-inverted;
    background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue, 0.15) 100%);
    border-radius: 16px;
    transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out, border 0.3s ease-in-out;

    h6, p {
        opacity: .85;
    }
}

.services-summary-container:hover {
    border: 1.5px solid $accent-blue;
    transform: translateY(-5px);
    background-image: linear-gradient(180deg, rgba($main-background-dark-inverted, 0.05) 0%, rgba($accent-blue, 0.25) 100%);

    h6, p {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .services-summary-container {
        min-height: 24em;
    }
}

@media screen and (max-width: 767px) {
    .services-summary-container {
        margin-bottom: 1.5em;
    }

    .services-summaries-container {
        img {
            height: 10em;
            width: auto;
        }

        p {
            padding: .15em 2em;
        }
    }

    .services-image-container {
        margin-bottom: 1.5em;
    }
}