@import '../../index.scss';

.prices-subsection .list-group-item {
    padding-left: .5em;
    padding-right: .5em;
}

.prices-section-card-container {
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .prices-section-card-container {
        margin: 1em 2em;
    }
}
