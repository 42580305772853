$main-background-dark: #1b1c25;
$main-background-light: #ececec;
$accent-blue: #2d76f5;
$accent-teal: #009494;
$main-background-dark-inverted: lighten($main-background-dark, 20%);
$neutral-white: #f7f7f7;

html, body {
	margin: 0;
	padding: 0;
}

html {
    scroll-behavior: smooth;
}

.text-blue {
	color: $accent-blue !important;
}

.gradient-text-blue {
	background: linear-gradient(90deg, $accent-blue, #5a95f7);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	color: transparent;
}

.dark-background-gradient {
    background-color: $main-background-dark;
	background: radial-gradient(circle at center, lighten($main-background-dark, 10%), $main-background-dark 70%), linear-gradient(155deg, $main-background-dark 0%, lighten($main-background-dark, 5%) 100%);
}

.light-background-gradient {
	background-color: $neutral-white;
    background: radial-gradient(circle at center, rgba($accent-teal, 0.075), rgba($neutral-white, 0) 50%),
				radial-gradient(circle at top left, rgba($accent-blue, 0.1), rgba($neutral-white, 0) 50%), 
                radial-gradient(circle at bottom right, rgba($accent-blue, 0.1), rgba($neutral-white, 0) 50%);

	@media (max-width: 767px) {
		background: radial-gradient(circle at center, rgba($accent-teal, 0.05), rgba($neutral-white, 0) 25%),
		 			radial-gradient(circle at top left, rgba($accent-blue, 0.1), rgba($neutral-white, 0) 25%), 
					radial-gradient(circle at bottom right, rgba($accent-blue, 0.1), rgba($neutral-white, 0) 25%);
	}
}

.action-link {
    position: relative;
    color: $accent-teal;
    text-decoration: none;
    transition: color 0.3s ease;

    &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: $accent-teal;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.4s ease, background-color 0.4s ease;
        transition-delay: 0.1s; 
    }

    &:hover {
        color: $accent-blue; 

        &::after {
            transform: scaleX(1);
            transform-origin: left;
            background-color: $accent-blue;
            transition-delay: 0.05s; 
        }
    }

    &:not(:hover) {
        &::after {
            background-color: $accent-teal;
            transform: scaleX(0);
            transform-origin: right;
            transition-delay: 0s;
        }
    }
}


