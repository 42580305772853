@import '../../index.scss';

.row.services-subsection-main-section {
    margin-top: 4em;
}

.services-blurb-container {
    margin-bottom: 1.5em;
}

.services-blurb-content-container {
    padding: 1.5em;
    margin: .75em;
    border: 1px solid #fff;
    border-radius: .5rem;
    height: 100%;
    background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue, 0.2) 100%);
    transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out, border 0.3s ease-in-out;
}

.services-blurb-content-container:hover {
    transform: translateY(-5px); 
    border: 1px solid $accent-blue;
    background-image: linear-gradient(180deg, transparent 0%, rgba($accent-blue, 0.4) 100%);

    .services-blurb-paragraph {
        opacity: 1;
    }
}

.services-blurb-svg {
    width: 5em;
    margin-bottom: 1em;
}

.services-blurb-paragraph {
    opacity: .75;
    transition: opacity 0.2s ease-in-out;
}

@media screen and (min-width: 768px) {
    .services-summary {
        font-size: 1.25em;
        padding: 0 .5em;
    }
}

@media screen and (max-width: 767px) {
    .services-blurb-container {
        font-size: .75em;
    } 

    .services-summary {
        font-size: 1em;
        padding: 1.5em;
    }

    .services-blurb-content-container {
        margin: 2em;
    }

    .services-blurb-paragraph {
        font-size: 1.25em;
    }
}
