@import '../../index.scss';

.hero-main-header {
    color: #fff;
    font-size: 3.25em;
}

.hero-subtext {
    font-size: 1.33em;
}

.hero-container {
    background-image: url(../../../assets/wave.svg);
    background-repeat: no-repeat;
    background-position: bottom;
}

.hero-graphic {
    height: auto;
    max-width: 95%;
}

@media screen and (min-width: 768px) {
    .hero-container {
        padding-top: 10em;
        padding-bottom: 2.5em;
    }

    .hero-graphic {
        margin-left: 1.5em;
        margin-top: 3em;
    }
}

@media screen and (max-width: 767px) {
    .hero-container {
        padding-top: 8em;
        padding-bottom: 3em;
        background-size: 150%;
    }

    .hero-main-header {
        font-size: 2em;
    }

    .hero-subtext {
        font-size: 1em;
    }

    .hero-graphic {
        margin-top: 3em;
        width: 75%;
    }
}

.hero-subtext {
    color: #fff;
}

.btn.hero-button {
    color: $neutral-white;
    background-color: #007A7A;
    border: 3px solid #007A7A;
    transition: background-color 0.3s ease, border-color 0.3 ease, color ease;
}

.btn.hero-button:hover {
    background-color: $accent-blue;
    border: 3px solid $neutral-white;
    color: $neutral-white;
}