@import '../index.scss';

.about-page-content-container {
    background: $neutral-white;

    h1 {
        margin-bottom: .5em;
        color: $main-background-dark-inverted;
    }

    p {
        color: $main-background-dark-inverted;
        margin-bottom: .75rem;
    }
}

.why-choose-me-section {
    h2, li {
        color: $main-background-dark-inverted;
    }

    li {
        margin-top: 1em;
    }
}

.why-choose-me-container {
    margin-bottom: 2em;
}

.headshot-about-page {
    filter: saturate(85%);
    border-radius: 0.5rem;
    max-width: inherit;
    margin-top: .5em;
    box-shadow: 0 0 .75em .5em rgba(0, 0, 0, .1);
}
