@import '../../index.scss';

.transition-navbar {
    background-color: $main-background-dark; 
    transition: background-color 0.3s ease, padding 0.3s ease;
}

.transition-navbar.top {
    padding: 1em; 
}

.transition-navbar.scrolled {
    padding: 0 1rem; 
    background-color: $neutral-white !important; 
    border-bottom: 1.5px solid $main-background-dark-inverted;

    .nav-link-text {
        color: lighten($main-background-dark, 20%);
    }
}

.navbar-dark {
    background-color: transparent;
}

.logo-image {
  width: 10em;
  transition: opacity 0.2s ease;
}

.navbar-brand {
    padding: 0 !important;
}

.logo-image {
    cursor: pointer;
}

.nav-icon {
    color: $accent-teal;
}

.nav-link-text {
    color: #fff;
    position: relative;
}

.nav-link:hover .nav-link-text{
    color: $accent-teal;
}

.nav-link-active .nav-link-text::after {
    content: '';
    position: absolute;
    bottom: -5px; 
    left: 0; 
    width: 100%; 
    height: 4px;
    background-color: $accent-blue;
    transition: none; 
    z-index: 1;
}

.top-nav-link-icon {
    font-size: 1.15rem; 
    font-weight: bold; 
    stroke-width: 1.5; 
    margin-right: .15em;
}

.top-nav-menu {
    .fs-5 {
        font-size: 1.15rem !important;
    }
}

.offcanvas-body .nav-link-text {
    color: $main-background-dark !important;
}

.offcanvas {
    width: 50vw !important;
    height: 100vh !important;
    background: $neutral-white !important;
}

.offcanvas-header {
    padding: 1.5em 1.75em 1.5em 0 !important;
}

.offcanvas-body {
    padding-top: 0 !important;
}

.mobile-nav-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nav-link-mobile {
    cursor: pointer;
    color: $accent-teal;
    padding-right: .5em;
}

.nav-link-active-mobile {
    color: $accent-blue;
    border-right: 4px solid $accent-blue;
}

@media screen and (max-width: 767px) {
    .navbar-collapse-bg-fixed.navbar-collapse {
        background: $main-background-dark;
    }
}

@media screen and (min-width: 768px) {
    .nav-link .nav-icon {
        transition: transform 0.1s ease-in-out;
    }
      
    .nav-link:hover .nav-icon {
        transform: translateX(3px);
    }
}