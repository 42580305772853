@import '../../index.scss';

.row.about-subsection-main-section {
    margin-top: 4em;
}
  
.image-container {
    position: relative;
    display: inline-block;
}

.headshot-image {
    max-width: 17em;
    border-radius: 0.5rem;
    border: 3px solid #fff;
    filter: grayscale(100%);
    transition: filter 0.2s ease-in-out, border 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.tint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $accent-blue; 
    opacity: 0.2;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    border-radius: 0.5rem;
}

.image-container:hover .headshot-image {
    filter: grayscale(0%) saturate(70%);
    transform: translateY(-5px); 
    border: 3px solid $accent-blue; 
}

.image-container:hover .tint-overlay {
    opacity: 0; 
}

.headshot-container {
    position: relative; 
    overflow: hidden; 
    padding-top: 1em;
}

.image-container {
    position: relative; 
}

.headshot-image {
    display: block;
    width: 100%;
    height: auto;
}
@media screen and (min-width: 768px) {
    .about-subsection-summary {
        font-size: 1.25em;
        padding: 0 .5em;
    }
}

@media screen and (max-width: 767px) {
    .about-subsection-summary {
        font-size: 1em;
        padding: 1.5em;
    }

    .about-section-image-row {
        background-position: top; 
    }

    .headshot-image {
        max-width: 15em;
    }

    .headshot-container {
        padding-bottom: 3em;
        padding-top: 1em;
    }
}

@media (min-width: 768px) and (max-width: 990px) {
    .headshot-container {
        padding: 6em;
    }
}
