@import '../../index.scss';

.contact-form-container {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2em;

    form {
        padding: .5em;
    }

    h2 {
        color: $main-background-dark-inverted;
    }

    button {
        background: $accent-teal;
        border: 2px solid $accent-teal;

        &:hover {
            background: $accent-blue;
            border: 2px solid $accent-teal;
        }
    }

    textarea {
        height: 8em;
    }
}

.contact-form-container button:hover {
    background: $accent-blue;
    border: 2px solid $accent-blue;
}

.contact-form-container {
    .col {
        margin-bottom: 1em; 
    }
}
